<template>
  <div class="forecast-results">
    
    <div class=" padding-20 font-size-20  ">  <i class="el-icon-arrow-left font-size-24 bold  margin-right-10 link" @click="$router.go(-1)"></i>查看历史</div>
    <div class="column margin-lr-20" style="margin-bottom:0" v-loading="loading">
      
      <div class="flex-row align-items-center justify-between border-bottom padding-bottom-10">
      
        <div class="flex-row align-items-center">
         
          <div>
            <span class="color-sub">选择时间：</span>
            <el-date-picker
              style="width: 220px;"
              size="mini"
              :key="modelFreq"
              v-model="dateRangeVlaue"
              :type="modelFreq=='week'?'daterange':'monthrange'"
              :value-format="modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM'"
              range-separator="至"
              :start-placeholder="modelFreq=='week'?'开始日期':'开始日期'"
              :end-placeholder="modelFreq=='week'?'结束月份':'结束月份'"
              :clearable="false"
              :picker-options="setDateRange"
              @change="getPredictPlan">
            </el-date-picker>
          </div>
          <div class="color-sub margin-left-20">选择方案：</div>
          <div>
            <el-select v-model="expertTypeId" size="mini" @change="selectexpertType()" placeholder="请选择">
              <el-option
                v-for="item in expertTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
           
            
          </div>
        </div>
        <div class="flex-row align-items-center">
          <el-button plain size="mini" @click="downLoadDataBySelect">数据下载</el-button>
          <el-button plain size="mini" @click="getComparison">对比查看</el-button>
        </div>
      </div>  

  
      <el-table
        ref="datatable"
        :height="$root.docHeight-225"
        size="medium"
        :header-cell-class-name="row=>{return row.rowIndex==0&&row.columnIndex==0?'disable-all-selection':''}"
        :data="dataList"
        @selection-change="handleSelectionChange">
        <div slot="empty"><el-empty :image-size="150"></el-empty></div>
        <el-table-column
          type="selection"
          width="55">
        </el-table-column>
        <el-table-column label="预测时间" prop="factorUpdate"></el-table-column>
        <el-table-column label="预测产品" prop="productName" width="150"></el-table-column>
        <el-table-column label="预测目标" prop="targetName"></el-table-column>
        <el-table-column label="预测方案" prop="customModelName"></el-table-column>
        <el-table-column label="操作" prop="" width="160">
          <template slot-scope="props">
            <div class="flex-row">
              <div class="link-text margin-right-20" @click="getPredictResult(props.row)">预测结果</div>
              <div class="link-text" @click="getBackVerify(props.row.customModelId)">模型精度</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
     <!-- 预测结果弹窗 -->
     <resultdialog :resultDetail="resultDetail" :modelFreq="modelFreq"></resultdialog>
   
       <!-- 模型精度弹窗 -->
      <el-dialog custom-class="custom-dialog" :visible.sync="verifyRateDetail.visible" class="forecast-results-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true" top="100px" width="60%">
        <div slot="title">
          <el-tabs v-model="verifyRateDetail.activeTab">
            <el-tab-pane label="模型精度" name="chart"></el-tab-pane>
            <el-tab-pane label="数据详情" name="table"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="flex-column" :style="{'height': $root.docHeight-300+'px'}" v-loading="verifyRateDetail.loading">
          <template v-if="verifyRateDetail.activeTab=='chart'">
            <div class="color-sub font-size-12">
              统计周期：<template v-if="verifyRateDetail.data.startTime||verifyRateDetail.data.endTime">{{verifyRateDetail.data.startTime | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM-dd')}}至{{verifyRateDetail.data.endTime | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM-dd')}}</template>
            </div>
            <div class="flex-row margin-top-20">
              <div style="margin-right:5%">
                <p class="color-sub">移动1期平均偏差率</p>
                <p class="font-size-20">{{verifyRateDetail.data.step1DeviationRateAvg | numFormat(2,'-')}}%</p>
              </div>
            </div>
            <Chart :option="verifyRateChart" class="flex-auto"></Chart>
          </template>
          <template v-if="verifyRateDetail.activeTab=='table'">
            <el-table
              :height="$root.docHeight-300"
              :data="verifyRateDetail.data.dataList"
              size="medium"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="80"></el-empty></div>
              <el-table-column label="时间">
                <template slot-scope="props">{{props.row.dataCycle}}</template>
              </el-table-column>
              <el-table-column label="真实值">
                <template slot-scope="props">{{props.row.his | numFormat(2,'-')}}</template>
              </el-table-column>
              <el-table-column label="移动1期" align="center">
                <el-table-column label="验证值">
                  <template slot-scope="props">{{props.row.step1Value | numFormat(2,'-')}}</template>
                </el-table-column>
                <el-table-column label="偏差值">
                  <template slot-scope="props">{{props.row.step1DeviationValue | numFormat(2,'-')}}</template>
                </el-table-column>
                <el-table-column label="偏差率">
                  <template slot-scope="props">{{props.row.step1DeviationRate | numFormat(2,'-')}}{{ $numFormat(props.row.step1DeviationRate)?'%':'' }}</template>
                </el-table-column>
              </el-table-column>
              
            </el-table>
          </template>
        </div>
      </el-dialog>
      <!-- 对比弹窗 -->
     <el-dialog custom-class="custom-dialog" :visible.sync="comparisonDetail.visible" class="forecast-results-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true" top="100px" width="60%">
        <div slot="title">
          <el-tabs v-model="comparisonDetail.activeTab">
            <el-tab-pane label="预测结果" name="chart"></el-tab-pane>
            <el-tab-pane label="数据详情" name="table"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="flex-column" :style="{'height': $root.docHeight-300+'px'}" v-loading="comparisonDetail.loading">
          <template v-if="comparisonDetail.activeTab=='chart'">
            <div class="color-sub font-size-12">
              统计周期：<template v-if="comparisonDetail.data.startTime||comparisonDetail.data.endTime">{{comparisonDetail.data.startTime | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM-dd')}}至{{comparisonDetail.data.endTime | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM-dd')}}</template>
            </div>
            <Chart :option="comparisonChart" class="flex-auto"></Chart>
          </template>
          <template v-if="comparisonDetail.activeTab=='table'">
            <el-table
              :height="$root.docHeight-300"
              :data="comparisonDetail.data.dataList"
              size="medium"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="80"></el-empty></div>
              <el-table-column label="时间">
                <template slot-scope="props">{{ props.row.dataCycle }}</template>
              </el-table-column>
              <el-table-column v-for="(item,index) in comparisonDetail.data.items" :key="index" :label="item.name">
                <template slot-scope="props">{{ props.row[item.id] | numFormat(2,'-') }}</template>
              </el-table-column>
            </el-table>
          </template>
        </div>
      </el-dialog>
  </div>
</template>

<script>
//自古是uncoond
import Chart from "@/components/Chart";
import exportExcel  from "@/common/exportExcel";
import crypto from "@/common/crypto";
import Resultdialog from './components/resultdialog.vue';
export default {
  name: 'ForecastResults',
  components:{
    Chart,
    Resultdialog,
  },
  data (){
    return{
      setDateRange: {
        disabledDate: time => {
          // 禁用今天之后的日期【当前天可选】
          return time > new Date();
        },
        // firstDayOfWeek:1
      },
      dateRangeVlaue:'',//选择的时间范围
      productId:[],//选择的产品
      expertTypeList:[{name:'全部',id:''},{name:'模型自估',id:'uncond'},{name:'情景模拟',id:'cond'}],
      expertTypeId:'',//选择模型类型
      multipleSelection: [],//勾选的列表
      loading:false,
      dataList: [],
      resultDetail:{//预测结果弹窗
        visible:false,
        activeTab:'chart',
        data:{}
      },
      verifyRateDetail:{//模型精度弹窗
        visible:false,
        activeTab:'chart',
        data:{}
      },
      comparisonDetail:{//对比弹窗
        visible:false,
        activeTab:'chart',
        data:{}
      },
      modelId:'',
      modelFreq:''
    }
  },
  computed:{
   
    //模型精度图表
    verifyRateChart(){
      let chartData=this.verifyRateDetail.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.xAxis.boundaryGap = true
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        opt.legend.data=chartData.items.filter(v=>{return v.id!='step1DeviationValue'&&v.id!='step3DeviationValue'&&v.id!='step4DeviationValue'&&v.id!='step12DeviationValue'}).map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity: v.id!='step1DeviationRate'&&v.id!='step3DeviationRate'&&v.id!='step4DeviationRate'&&v.id!='step12DeviationRate'?0:1
            }
          }
        })
        opt.yAxis=[
          {
            type: 'value',
            name:chartData.items.find(v=>{return v.id=='his'}).unit,
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine: {
              lineStyle: {
                type:'dashed'
              }
            },
            scale:true,
          },
          {
            type: 'value',
            name:'',
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine:{
              show:false
            },
            axisLabel:{
              formatter: '{value}%'
            },
            min:0,
            max:100
          }
        ]
        opt.series=chartData.items.filter(v=>{return v.id!='step1DeviationValue'&&v.id!='step3DeviationValue'&&v.id!='step4DeviationValue'&&v.id!='step12DeviationValue'}).map(v=>{
          let isLine=(v.id!='step1DeviationRate'&&v.id!='step3DeviationRate'&&v.id!='step4DeviationRate'&&v.id!='step12DeviationRate')
          return isLine? {
            name: v.name,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id!='his'?"dashed":"solid"
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }:{ 
            name: v.name,
            type: 'bar', 
            yAxisIndex:1,
            barMaxWidth:15,  
            barGap:'-35%',
            

            itemStyle:{ 
              borderRadius:[10,10,0,0],
              opacity:0.5
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit||""}</div>`
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
    //预测结果图表
    comparisonChart(){
      let chartData=this.comparisonDetail.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.xAxis.boundaryGap = false
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        opt.legend.data=chartData.items.map(v=>{
          return {
            name:v.name,
            unit:v.unit||'',
            itemStyle:{
                opacity: 0
            }
          }
        })
       // opt.yAxis.name=chartData.items.find(v=>{return v.expertType=='his'})?chartData.items.find(v=>{return v.expertType=='his'}).unit:''
        opt.series=chartData.items.map(v=>{
          return {
            name: v.name,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.expertType!='his'?"dashed":"solid"
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit||""}</div>`
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
  },
  created(){
    this.initDate()
    this.getData()
  },
  mounted(){

  },
  methods:{
    getData(){
     // if(this.productList.length){
        this.getPredictPlan()
     // }
    },
    //初始化日期
    initDate(){
      try {
        let q=JSON.parse(crypto.decrypt(this.$route.query.q))
        console.log(q)
        this.modelId=q.modelId;
        this.modelFreq=q.modelFreq;
      } catch (error) {
        this.modelId=''
        this.modelFreq=''
      }
      let currentDate = new Date(); // 获取当前日期
      currentDate.setMonth(currentDate.getMonth() - (this.modelFreq=='week'?1:2)); // 将当前日期减去N天
      this.dateRangeVlaue = this.modelFreq=='week'?[currentDate.format('yyyy-MM-dd'),new Date().format('yyyy-MM-dd')]:[currentDate.format('yyyy-MM'),new Date().format('yyyy-MM')]
    },
    
    //选择产品
    selectProduct(){
     
      this.getPredictPlan()
    },
    //选择方案类型
    selectexpertType(){
      this.getPredictPlan()
    },
    //勾选列表
    handleSelectionChange(val) {
      this.multipleSelection = val;
      if(val.length>6){
        this.$refs.datatable.toggleRowSelection(val[val.length-1],false);
        this.$message({message: '最多选择6个预测方案',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
      }
    },
    //获取列表信息
    getPredictPlan(){
      this.loading=true
      let request=this.$instance.get('/befarCustomModel/getModelHistoryList',{
          params: {
           modelId:this.modelId,
           modelFreq:this.modelFreq,
           startTime:this.dateRangeVlaue[0],
           endTime:this.dateRangeVlaue[1],
           expertType:this.expertTypeId
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.dataList=res.data.info
        }else{
          this.dataList=[]
        }
      })
      request.catch(()=>{
        this.loading=false
        this.targetInfo=[]
      })
      return request
    },
    //获取预测结果
    getPredictResult(val){
      this.resultDetail.visible=true
      this.resultDetail.activeTab='chart'
      this.resultDetail.loading=true
      let request=this.$instance.get('/befarCustomModel/getHisPredictValue',{
          params: {
           //...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           customModelId:val.customModelId,
           publishId:val.publishId
          },
      })
      request.then(res=>{
        this.resultDetail.loading=false
        if(res.data.code==0){
          this.resultDetail.data=res.data.info
        }else{
          this.resultDetail.data={}
        }
      })
      request.catch(()=>{
        this.resultDetail.loading=false
        this.resultDetail.data={}
      })
      return request
    },
    //获取预测结果
    getBackVerify(id){
      this.verifyRateDetail.visible=true
      this.verifyRateDetail.activeTab='chart'
      this.verifyRateDetail.loading=true
      let request=this.$instance.get('/befarCustomModel/getBackVerify',{
          params: {
           ...this.$store.state.basicParams,
           modelFreq:this.modelFreq,
           customModelId:id
          },
      })
      request.then(res=>{
        this.verifyRateDetail.loading=false
        if(res.data.code==0){
          this.verifyRateDetail.data=res.data.info
        }else{
          this.verifyRateDetail.data={}
        }
      })
      request.catch(()=>{
        this.verifyRateDetail.loading=false
        this.verifyRateDetail.data={}
      })
      return request
    },
    //获取对比结果
    getComparison(){
      if(!this.multipleSelection.length){
        this.$message({message: '请选择要对比的预测方案',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false
      }
      this.comparisonDetail.visible=true
      this.comparisonDetail.activeTab='chart'
      this.comparisonDetail.loading=true
      let request=this.$instance.get('/befarCustomModel/getHisTargetPredictContrast',{
          params: {
           ...this.$store.state.basicParams,
           modelId:this.modelId,
           modelFreq:this.modelFreq,
           publishIds:this.multipleSelection.map(v=>{return v.publishId}).join(',')
          },
      })
      request.then(res=>{
        this.comparisonDetail.loading=false
        if(res.data.code==0){
          this.comparisonDetail.data=res.data.info
        }else{
          this.comparisonDetail.data={}
        }
      })
      request.catch(()=>{
        this.comparisonDetail.loading=false
        this.comparisonDetail.data={}
      })
      return request
    },
    //导出所选
    downLoadDataBySelect(){
      if(this.multipleSelection.length==0){
        this.$message({message: '请选择要下载的预测方案',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false;
      }
      let query={
        modelFreq:this.modelFreq,
        publishIds:this.multipleSelection.map(v=>{return v.publishId+'_custom'}).join(',')
      }
      exportExcel('/resultView/downLoadDataBySelect',query,'get');
    },
    //快捷导出
    // quickExport(command) {
    //   let query={
    //     modelFreq:this.modelFreq,
    //     cycle:command
    //   }
    //   exportExcel('/resultView/downLoadDataByCycle',query,'get');
    // },
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="scss" scoped>

 
</style>
<style lang="scss">

  .forecast-results-dialog{
    .el-tabs__header{margin: 0;}
    .el-dialog__body{
      padding-top:0px
    }
  }

</style>