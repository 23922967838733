<template>
    <div>
         <!-- 预测结果弹窗 -->
     <el-dialog custom-class="custom-dialog" :visible.sync="resultDetail.visible" class="forecast-results-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :append-to-body="true" top="100px" width="60%">
        <div slot="title">
          <el-tabs v-model="resultDetail.activeTab">
            <el-tab-pane label="预测结果" name="chart"></el-tab-pane>
            <el-tab-pane label="数据详情" name="table"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="flex-column" :style="{'height': $root.docHeight-300+'px'}" v-loading="resultDetail.loading">
          <template v-if="resultDetail.activeTab=='chart'">
            <div class="color-sub font-size-12">
              统计周期：<template v-if="resultDetail.data.startTime||resultDetail.data.endTime">{{resultDetail.data.startTime | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}至{{resultDetail.data.endTime | dateFormat(modelFreq=='week'?'yyyy-MM-dd':'yyyy-MM')}}</template>
            </div>
            <div class="margin-top-20">
              <p class="color-sub">平均偏差率</p>
              <p class="font-size-20">{{resultDetail.data.deviationRateAvg | numFormat(2,'-')}}%</p>
            </div>
            <Chart :option="resultChart" class="flex-auto"></Chart>
          </template>
          <template v-if="resultDetail.activeTab=='table'">
            <el-table
              :height="$root.docHeight-300"
              :data="resultDetail.data.dataList"
              size="medium"
              style="width: 100%">
              <div slot="empty"><el-empty :image-size="80"></el-empty></div>
              <el-table-column label="时间">
                <template slot-scope="props">{{ props.row.dataCycle }}</template>
              </el-table-column>
              <el-table-column label="真实值">
                <template slot-scope="props">{{ props.row.his | numFormat(2,'-') }}</template>
              </el-table-column>
              <el-table-column label="预测值">
                <template slot-scope="props">{{ props.row.predic | numFormat(2,'-') }}</template>
              </el-table-column>
              <el-table-column label="偏差值">
                <template slot-scope="props">{{ props.row.deviationValue | numFormat(2,'-') }}</template>
              </el-table-column>
              <el-table-column label="偏差率">
                <template slot-scope="props">{{ props.row.deviationRate | numFormat(2,'-') }}{{$numFormat(props.row.deviationRate)?'%':''}}</template>
              </el-table-column>
            </el-table>
          </template>
        </div>
      </el-dialog>
    </div>
</template>
<script>
import Chart from "@/components/Chart";
export default ({
   name:'resultdialog',
   components:{
     Chart,
   },
   data(){
    return{

    }
   },
   props:{
    resultDetail:{
        default:{
          visible:false,
          activeTab:'chart',
          data:{}
        }
    },
    modelFreq:{
      default:'week'
    }
   },
   computed:{
    //预测结果图表
    resultChart(){
      let chartData=this.resultDetail.data
      if(chartData.items){
        let opt =JSON.parse(JSON.stringify(this.$defaultChartOption)) 
        opt.xAxis.boundaryGap = true
        opt.xAxis.data=chartData.dataList.map(v=>{return v.dataCycle})
        opt.legend.data=chartData.items.filter(v=>{return v.id!='deviationValue'}).map(v=>{
          return {
            name:v.name,
            unit:v.unit,
            itemStyle:{
                opacity: v.id!='deviationRate'?0:1
            }
          }
        })
        opt.yAxis=[
          {
            type: 'value',
            name:chartData.items.find(v=>{return v.id=='his'}).unit,
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine: {
              lineStyle: {
                type:'dashed'
              }
            },
            scale:true,
          },
          {
            type: 'value',
            name:'',
            nameLocation: 'end' ,
            nameGap: 5,
            nameTextStyle:{
              align:'left'
            },
            splitLine:{
              show:false
            },
            axisLabel:{
              formatter: '{value}%'
            },
            min:0,
            max:100
          }
        ]
        opt.series=chartData.items.filter(v=>{return v.id!='deviationValue'}).map(v=>{
          let isLine=(v.id!='deviationRate')
          return isLine? {
            name: v.name,
            type: 'line', 
            connectNulls:true,
            lineStyle:{
              type:v.id!='his'?"dashed":"solid"
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }:{ 
            name: v.name,
            type: 'bar', 
            yAxisIndex:1,
            barMaxWidth:15,  
            // barGap:'-35%',
            itemStyle:{ 
              borderRadius:[10,10,0,0],
              opacity:0.5
            },
            data: chartData.dataList.map(vv=>{return vv[v.id]}),
          }
        }) 
        opt.tooltip.formatter = (params)=>{
          let str=`<div>${params[0].axisValue}</div>`
          params.forEach((val)=>{
            if(this.$numFormat(val.data)){
              str+=`<div>${val.marker}${val.seriesName}：${this.$numFormat(val.data,2)}${opt.legend.data[val.seriesIndex].unit||""}</div>`
            }
          })
          return str
        }
        return opt
      }else{
        return null
      }
    },
   }
})
</script>

<style scoped>

</style>